import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {hashSync, genSaltSync} from 'bcrypt-ts'

const Login = (props:any) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()

  const onButtonClick = async () => {
    // Set initial error values to empty
    setEmailError('')
    setPasswordError('')
    let error = false
  
    if ('' === email) {
      setEmailError('Please enter your email')
      error = true
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email')
      error = true
    }
  
    if ('' === password) {
      setPasswordError('Please enter a password')
      error = true
    } else if (password.length < 7) {
      setPasswordError('The password must be 8 characters or longer')
      error = true
    }

    if (error){
        return
    }
    //TODO auth calls

    props.setLoggedIn(true)
    props.setEmail(email)
    const salt = genSaltSync(14)
    props.setPassword(hashSync(password, salt))
    navigate('/')
  }

  return (
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>Login</div>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={email}
          type="email"
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={password}
          type="password"
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Log in'} />
      </div>
    </div>
  )
}

export default Login